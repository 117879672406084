import useSWR from 'swr-0-5-6'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'
import { Socket } from '~/modules/SDK/socket2/Socket'

// { 1101: { name: '台泥', percentage: 0.5964 }, 1102: { name: '亞泥', percentage: '0.3525' } , ..., }
type WeightedStocksResourceData = {
  [k in Socket.SymbolString]: {
    name: string
    percentage: number
  }
}

type TradeInfo = {
  t: number[]
  foreigner_txf: { net_oi_volume: number[]; oi_diff: number[] }
  foreigner_mxf: { net_oi_volume: number[]; oi_diff: number[] }
  foreigner_opt: {
    net_oi_value: number[]
    net_oi_volume: number[]
    oi_value_diff: number[]
    oi_volume_diff: number[]
  }
  foreigner_twse: { trading_value_diff: number[] }
  foreigner_tpex: { trading_value_diff: number[] }
  dealer_txf: { net_oi_volume: number[]; oi_diff: number[] }
  dealer_mxf: { net_oi_volume: number[]; oi_diff: number[] }
  dealer_opt: {
    net_oi_value: number[]
    net_oi_volume: number[]
    oi_value_diff: number[]
    oi_volume_diff: number[]
  }
  dealer_twse: { trading_value_diff: number[] }
  dealer_tpex: { trading_value_diff: number[] }
  large_trader_futures_last5: { oi: number[]; oi_diff: number[] }
  large_trader_futures_top5: { oi: number[]; oi_diff: number[] }
  large_trader_futures_top10: { oi: number[]; oi_diff: number[] }
  large_trader_options_last5: { oi: number[]; oi_diff: number[] }
  large_trader_options_top5: { oi: number[]; oi_diff: number[] }
  large_trader_options_top10: { oi: number[]; oi_diff: number[] }
  retail_oi: { oi: number[]; oi_diff: number[] }
  trust_mxf: { net_oi_volume: number[]; oi_diff: number[] }
  trust_txf: { net_oi_volume: number[]; oi_diff: number[] }
  trust_tpex: { trading_value_diff: number[] }
  trust_twse: { trading_value_diff: number[] }
}

const weightURL = apirc.stock.stockWeightURL
const unifyTableURL = apirc.unclassified.unifyTableURL

export const useStockWeightResource = (exchange: 'TWSE' | 'TPEX') => {
  // WORKAROUND: 這個後輟斜線一定得加，不然會 cors ERROR
  const swr = useSWR<WeightedStocksResourceData>(urlcat(weightURL + '/', { exchange }))
  const data = swr.data
  return data ? Object.entries(data) : []
}

export const useUnifyTableResource = (from: number, to: number) => {
  const swr = useSWR<TradeInfo>(
    // WORKAROUND: 這個後輟斜線一定得加，不然會 cors ERROR
    urlcat(unifyTableURL + '/', {
      from,
      to,
    }),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  )
  return swr.data
}
